/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "why-do-my-ears-feel-clogged",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-my-ears-feel-clogged",
    "aria-label": "why do my ears feel clogged permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do my ears feel clogged?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many possible reasons why your ears might feel clogged or muffled. It is a common problem that many people experience at some point, but if you have this feeling more frequently, it could be a sign of an underlying issue. Find below the most common reasons why your ears feel clogged."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "5-common-reasons-why-my-ears-feel-clogged",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-common-reasons-why-my-ears-feel-clogged",
    "aria-label": "5 common reasons why my ears feel clogged permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5 Common Reasons why my ears feel clogged"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "impacted-ear-wax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#impacted-ear-wax",
    "aria-label": "impacted ear wax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Impacted ear wax"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Earwax, also known as cerumen, is a normal and necessary part of the ear's anatomy. It helps to protect the ear canal by trapping dirt and other foreign particles, and it also has antibacterial properties. However, sometimes earwax can build up in the ear canal and become impacted, leading to symptoms such as a feeling of fullness or pressure in the ear, muffled hearing, and occasionally pain or discomfort.\nImpacted earwax can sometimes cause temporary hearing loss, as the wax can block sound waves from reaching the eardrum. If the earwax blockage is not removed, it can lead to long-term hearing loss."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "sinus-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sinus-pressure",
    "aria-label": "sinus pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sinus pressure"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sinus pressure can sometimes cause temporary hearing loss. This is because the sinuses and the ears are connected through the Eustachian tube, which helps to equalize the pressure between the middle ear and the outside environment. When the sinuses become inflamed or congested due to a sinus infection or allergies, it can cause a blockage in the Eustachian tube and lead to a feeling of fullness or pressure in the ear. This can also cause temporary hearing loss, as the pressure in the ear is not able to equalize properly."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "fluid-in-the-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fluid-in-the-ear",
    "aria-label": "fluid in the ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fluid in the ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several common causes of fluid buildup in the ear. Middle ear infections, also known as otitis media, can cause fluid to build up behind the eardrum and lead to a feeling of fullness or pressure in the ear. This condition often resolves on its own, but it can be painful. If the pain is severe, if you notice a fluid discharge, or if the symptoms persist for more than a day, it is important to see a doctor, especially for children under six months of age.\nEarwax helps to prevent water from entering the ear when you swim or bathe, but sometimes water can still get trapped in the ears. To help encourage trapped water to drain, you can try tilting your head sideways and gently pulling on your earlobe, using a warm compress to open up the Eustachian tubes, or using techniques like yawning, chewing, taking a deep breath, or using the Valsalva maneuver to help open up the tubes and allow the water to drain."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "noise-damage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#noise-damage",
    "aria-label": "noise damage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Noise Damage"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Loud noises can damage the hair cells in the inner ear, which are responsible for converting sound waves into electrical signals that the brain can interpret as sound. When these hair cells are damaged, it can lead to hearing loss.\nNoise-induced hearing loss can be temporary or permanent, depending on the intensity and duration of the noise exposure. Prolonged exposure to loud noises, such as those found in construction, manufacturing, and farming, can lead to permanent hearing loss. In addition, repeated exposure to loud noises, such as those found at concerts and sporting events, can also cause permanent hearing loss over time."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To protect your hearing, it is important to avoid loud noises or wear hearing protection when necessary. If you think you may have noise-induced hearing loss, it is important to see a doctor or audiologist for an evaluation. Early diagnosis and treatment can help to prevent further deterioration of your hearing and improve your quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signs-of-hidden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signs-of-hidden-hearing-loss",
    "aria-label": "signs of hidden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signs of hidden hearing loss"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "struggling-to-hear-certain-sounds",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#struggling-to-hear-certain-sounds",
    "aria-label": "struggling to hear certain sounds permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Struggling to hear certain sounds"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you find it difficult to hear certain high-pitched sounds, such as women's and children's voices or the sound of birds chirping, you may have hidden hearing loss."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "asking-people-to-repeat-themselves",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#asking-people-to-repeat-themselves",
    "aria-label": "asking people to repeat themselves permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Asking people to repeat themselves"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you frequently ask people to repeat themselves or if you struggle to follow conversations, especially in noisy environments, it could be a sign of hearing loss."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "turning-up-the-volume",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#turning-up-the-volume",
    "aria-label": "turning up the volume permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Turning up the volume"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you find that you need to turn up the volume on your TV or radio more than usual, it could be a sign of hearing loss."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "struggling-to-understand-phone-conversations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#struggling-to-understand-phone-conversations",
    "aria-label": "struggling to understand phone conversations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Struggling to understand phone conversations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have trouble understanding phone conversations, especially when the speaker is not in the same room as you, it could be a sign of hidden hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-assistance-from-an-experienced-audiologist-near-your-location",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-assistance-from-an-experienced-audiologist-near-your-location",
    "aria-label": "get assistance from an experienced audiologist near your location permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get assistance from an experienced audiologist near your location"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have been experiencing any signs of clogged ear or hearing loss, it is important to see an audiologist to determine the cause and get appropriate ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " treatment. Swallowing and yawning can sometimes help to unclog your ears, but if these methods are not effective, you may need medical intervention."), "\n", React.createElement(LandingPageCta, {
    copy: "Get in touch with qualified Audiologist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
